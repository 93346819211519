<template>
  <v-navigation-drawer
    permanent
    clipped
    :mini-variant="isMiniVariant"
    drawerclass="app--drawer"
    app
  >
    <v-list nav>
      <v-list-item link active-class="primary" :to="{ name: 'Storage' }">
        <v-list-item-icon>
          <v-icon>mdi-folder</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Files</v-list-item-title>
      </v-list-item>
      <v-list-item link disabled>
        <v-list-item-icon>
          <v-icon>mdi-history</v-icon>
        </v-list-item-icon>
        <v-list-item-title>History</v-list-item-title>
      </v-list-item>
      <v-list-item link active-class="primary" :to="{ name: 'Users' }">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Users</v-list-item-title>
      </v-list-item>
      <v-spacer></v-spacer>
      <v-divider></v-divider>
      <v-list-item @click="$emit('sign-out')">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Sign out</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    isMiniVariant: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss"></style>
