import { API } from "aws-amplify";
export const users = {
  namespaced: true,
  state: () => ({
    users: [],
    isLoading: false
  }),
  mutations: {
    setUsers(state, users) {
      state.users = users;
    },

    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    }
  },
  getters: {
    getUsers(state) {
      return state.users;
    },
    getIsLoading(state) {
      return state.isLoading;
    }
  },
  actions: {
    async loadUserList(context) {
      const apiName = "amplifyUserListAPI";
      const path = "/users";
      const myInit = {
        response: true
      };
      context.commit("setIsLoading", true);
      try {
        const response = await API.get(apiName, path, myInit);
        context.commit("setUsers", response.data.users);
        context.commit("setIsLoading", false);
      } catch (error) {
        context.commit("setUsers", []);
        context.commit("setIsLoading", false);
      }
    }
  }
};
