import { API, Storage } from "aws-amplify";
export const storage = {
  namespaced: true,
  state: () => ({
    s3Data: [],
    selectedObject: null,
    isLoading: false,
    presignedUrl: "",
    uploadUrl: ""
  }),
  getters: {
    getS3Data(state) {
      return state.s3Data;
    },
    getSelectedObject(state) {
      return state.selectedObject;
    },
    getIsLoading(state) {
      return state.isLoading;
    },
    getPresignedUrl(state) {
      return state.presignedUrl;
    },
    getUploadUrl(state) {
      return state.uploadUrl;
    }
  },
  mutations: {
    setS3Data(state, s3Data) {
      state.s3Data = s3Data;
    },
    setSelectedObject(state, selectedObject) {
      state.selectedObject = selectedObject;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setPresignedUrl(state, presignedUrl) {
      state.presignedUrl = presignedUrl;
    },
    setUploadUrl(state, uploadUrl) {
      state.uploadUrl = uploadUrl;
    }
  },
  actions: {
    loadS3Data(context) {
      context.commit("setIsLoading", true);
      Storage.list("")
        .then(result => {
          const bytesToSize = bytes => {
            const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
            if (bytes == 0) return "0 Byte";
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
          };
          const tmp = result.map(s3data => {
            s3data.size = bytesToSize(parseInt(s3data.size));
            return s3data;
          });
          context.commit("setS3Data", JSON.parse(JSON.stringify(tmp)));
          context.commit("setIsLoading", false);
        })
        .catch(err => console.error(err));
    },
    deleteObject(context, key) {
      Storage.remove(key)
        .then(() => {
          this._vm.$toast.success(`${key}の削除が完了しました。`);
          context.commit("setSelectedObject", null);
          context.dispatch("loadS3Data");
        })
        .catch(err => console.error(err));
    },
    uploadObject(context, file) {
      this._vm.$toast.info(`${file.name}のアップロードを開始しました。`);
      Storage.put(file.name, file)
        .then(() => {
          this._vm.$toast.success(`${file.name}のアップロードが完了しました。`);
          context.dispatch("loadS3Data");
        })
        .catch(err => console.error(err));
    },
    setSelectedObject(context, payload) {
      if (payload.value) {
        const selectedObject = context.state.s3Data.find(
          item => item.key === payload.item.key
        );
        context.commit("setSelectedObject", selectedObject);
      } else {
        context.commit("setSelectedObject", null);
      }
    },
    async createPresignedUrl(context, payload) {
      await context.commit("setPresignedUrl", "");
      const apiName = "amplifyS3PresignedUrlAPI";
      const path = "/presignedurl/" + context.state.selectedObject.key;
      const myInit = {
        response: true,
        queryStringParameters: {
          expires_in: payload.expiresIn
        }
      };
      try {
        const response = await API.get(apiName, path, myInit);
        context.commit("setPresignedUrl", response.data.url);
      } catch (error) {
        console.error(error.response);
        context.commit("setPresignedUrl", "");
      }
    },
    async createUploadUrl(context, payload) {
      await context.commit("setUploadUrl", "");
      const apiName = "amplifyS3PresignedUrlAPI";
      const path = "/presignedurl/upload";
      const myInit = {
        response: true,
        queryStringParameters: {
          expires_in: payload.expiresIn,
          file_key: payload.fileKey
        }
      };
      try {
        const response = await API.get(apiName, path, myInit);
        context.commit("setUploadUrl", response.data.url);
      } catch (error) {
        console.error(error.response);
        context.commit("setUploadUrl", "");
      }
    }
  }
};
