<template>
  <v-app>
    <v-main>
      <AppSideNav
        v-if="isAuthenticated"
        :is-mini-variant="isMiniVariant"
        @sign-out="signOut"
      />
      <AppTopBar
        :is-authenticated="isAuthenticated"
        @toggle-navbar="toggleNavBar"
        @sign-out="signOut"
      />
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppTopBar from "./components/commons/AppTopBar/index.vue";
import AppSideNav from "./components/commons/AppSideNav/index.vue";
export default {
  name: "App",
  components: {
    AppTopBar,
    AppSideNav
  },
  data: () => {
    return {
      isMiniVariant: true
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["profile/getUser"] === null ? false : true;
    }
  },
  methods: {
    toggleNavBar() {
      this.isMiniVariant = !this.isMiniVariant;
    },
    signOut() {
      if (this.isAuthenticated) {
        this.$store.dispatch("profile/signOut");
        this.$router.push({ name: "Auth", params: {} });
      }
    }
  }
};
</script>
