<template>
  <v-dialog :value="disabled" persistent max-width="290">
    <v-card>
      <v-card-text class="subtitle-2 pt-5">
        {{ targetName }}
        <br />
        を削除しますか？
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          キャンセル
        </v-btn>
        <v-btn color="error" text @click="confirmed">
          削除
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalDeleteConfirmation",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    targetName: {
      type: String,
      default: ""
    }
  },
  methods: {
    confirmed() {
      this.$emit("confirmed");
      this.close();
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped></style>
