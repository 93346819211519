<template>
  <v-app-bar color="primary" clipped-left app dark>
    <v-app-bar-nav-icon
      v-if="isAuthenticated"
      @click.stop="$emit('toggle-navbar')"
    ></v-app-bar-nav-icon>
    <v-toolbar-title>S3 Wrapper</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
      v-if="isAuthenticated"
      target="_blank"
      @click="$emit('sign-out')"
      text
    >
      <span class="mr-2">Sign out</span>
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss"></style>
