import { Auth } from "aws-amplify";
export const profile = {
  namespaced: true,
  state: () => ({
    user: null
  }),
  mutations: {
    setUser(state, user) {
      state.user = user;
    }
  },
  getters: {
    getUser(state) {
      return state.user;
    }
  },
  actions: {
    signOut(context) {
      if (context.state.user !== null) {
        Auth.signOut()
          .then(() => {
            context.commit("setUser", null);
            this._vm.$toast.success("Sign Out に成功しました。");
          })
          .catch(() => {
            this._vm.$toast.error("Sign Out に失敗しました。");
          });
      } else {
        this._vm.$toast.error("Sign Out に失敗しました。");
      }
    }
  }
};
