<template>
  <v-data-table
    :headers="headers"
    :items="items"
    item-key="email"
    class="elevation-1"
    :loading="isLoading"
    loading-text="Loading..."
  >
    <template v-slot:top>
      <v-row>
        <v-col>
          <h2 class="ma-4">Users</h2>
        </v-col>
        <v-col xl="7" lg="8" md="12" sm="12" class="text-end">
          <v-btn outlined class="ma-3" large @click="$emit('refresh')">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Username",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Email", value: "email" },
        { text: "LastModified", value: "lastModified" }
      ]
    };
  },
  props: {
    items: {
      default: []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
