<template>
  <v-container>
    <StoragePagePresentational
      :s3-data="s3Data"
      :selected-object="selectedObject"
      :is-loading="isLoading"
      :presigned-url="presignedUrl"
      :upload-url="uploadUrl"
      @item-selected="itemSelected"
      @refresh="loadS3Data"
      @upload="upload"
      @delete="deleteObject"
      @create-presigned-url="createPresignedUrl"
      @create-upload-url="createUploadUrl"
    />
  </v-container>
</template>

<script>
import StoragePagePresentational from "../StoragePagePresentational/index.vue";
import { mapGetters } from "vuex";

export default {
  name: "StoragePageContainer",
  components: {
    StoragePagePresentational
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters({
      s3Data: "storage/getS3Data",
      selectedObject: "storage/getSelectedObject",
      isLoading: "storage/getIsLoading",
      presignedUrl: "storage/getPresignedUrl",
      uploadUrl: "storage/getUploadUrl"
    })
  },
  methods: {
    loadS3Data() {
      this.$store.dispatch("storage/loadS3Data");
    },
    itemSelected(payload) {
      this.$store.dispatch("storage/setSelectedObject", payload);
    },
    upload(file) {
      this.$store.dispatch("storage/uploadObject", file);
    },
    deleteObject() {
      this.$store.dispatch("storage/deleteObject", this.selectedObject.key);
    },
    createPresignedUrl(expiresIn) {
      this.$store.dispatch("storage/createPresignedUrl", { expiresIn });
    },
    createUploadUrl(expiresIn, fileKey) {
      this.$store.dispatch("storage/createUploadUrl", { expiresIn, fileKey });
    }
  }
};
</script>

<style></style>
