<template>
  <v-dialog :value="dialog" @click:outside="close" max-width="500px">
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          Set expiry time
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2">
          URL confirmation
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-3" height="200px">
            <v-card-title class="display">Presigned URL</v-card-title>
            <v-card-subtitle class="display"
              >署名付きURLの有効期限を入力してください。</v-card-subtitle
            >
            <v-form v-model="valid" @submit.prevent>
              <v-row justify="center" align="center">
                <v-col cols="10">
                  <v-text-field
                    label="Expired time"
                    v-model="expiredTime"
                    type="number"
                    min="0"
                    max="20"
                    suffix="Minutes"
                    Enables
                    autofocus
                    required
                    :rules="[
                      rules.required,
                      rules.upperLimit,
                      rules.lowerLimit
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
          <v-row justify="end">
            <v-spacer></v-spacer>
            <v-col>
              <v-btn text large block @click="close">
                Cancel
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                text
                color="secondary"
                @click="createPresignedUrl"
                large
                block
                :disabled="!valid"
                >Create
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-3" height="200px" :loading="presignedUrl === ''">
            <v-card-title class="display">Createed Presigned URL</v-card-title>
            <v-card-subtitle class="display">
              Presigned URLは、{{
                this.expiredTime
              }}分間使用できます。</v-card-subtitle
            >
            <v-row justify="center" align="center">
              <v-col cols="10">
                <v-text-field
                  outlined
                  :value="presignedUrl"
                  label="Presigned URL"
                  readonly
                  append-icon="mdi-clipboard-text"
                  ref="createdUrl"
                  @click="copyUrl"
                  @click:append="copyUrl"
                  hint="使用例: curl -o <保存したいファイル名> <発行したURL>"
                  persistent-hint
                />
              </v-col>
            </v-row>
          </v-card>

          <v-row>
            <v-spacer />
            <v-col>
              <v-btn
                color="secondary"
                text
                @click="close"
                block
                large
                :loading="presignedUrl === ''"
              >
                Close
              </v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalCreatePresignedUrl",
  data: () => {
    return {
      step: 1,
      valid: false,
      expiredTime: null,
      rules: {
        required: value => !!value || "必須項目です。",
        upperLimit: value =>
          parseInt(value) <= 20 || "最大の有効時間は20分です。",
        lowerLimit: value => parseInt(value) > 0 || "有効時間が0分以下です。"
      }
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    presignedUrl: {
      type: String,
      default: ""
    }
  },
  methods: {
    close() {
      this.step = 1;
      this.expiredTime = null;
      this.$emit("close");
    },
    copyUrl() {
      this.$refs.createdUrl.focus();
      navigator.clipboard.writeText(this.presignedUrl).catch(e => {
        console.error(e);
      });
      this.$toast.success("クリップボードにコピーしました。");
    },
    createPresignedUrl() {
      this.$emit("create-presigned-url", this.expiredTime);
      this.step = 2;
    }
  }
};
</script>

<style lang="scss" scoped></style>
