<template>
  <v-container>
    <UsersPagePresentational
      :user-list="userList"
      :is-loading="isLoading"
      @refresh="loadUserList"
    />
  </v-container>
</template>

<script>
import UsersPagePresentational from "../UsersPagePresentational/index.vue";
import { mapGetters } from "vuex";

export default {
  name: "UsersPageContainer",
  components: {
    UsersPagePresentational
  },
  computed: {
    ...mapGetters({
      userList: "users/getUsers",
      isLoading: "users/getIsLoading"
    })
  },
  methods: {
    loadUserList() {
      this.$store.dispatch("users/loadUserList");
    }
  }
};
</script>

<style></style>
