import Vue from "vue";
import VueRouter from "vue-router";
import Storage from "../views/StoragePageContainer/index.vue";
import Users from "../views/UsersPageContainer/index.vue";
import Auth from "../views/AuthPagePresentational/index.vue";
import * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import { AmplifyEventBus } from "aws-amplify-vue";
import store from "../store/index.js";

Vue.use(VueRouter);
Vue.use(AmplifyPlugin, AmplifyModules);

let user;

AmplifyEventBus.$on("authState", async state => {
  if (state === "signedOut") {
    user = null;
    store.commit("profile/setUser", null, { root: true });
    router.push({ path: "/auth" });
  } else if (state === "signedIn") {
    user = await getUser();
    router.push({ path: "/" });
  }
});

const getUser = async () => {
  return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
    .then(data => {
      if (data && data.signInUserSession) {
        store.commit("profile/setUser", data, { root: true });
        return data;
      }
    })
    .catch(() => {
      store.commit("profile/setUser", null, { root: true });
      return null;
    });
};

getUser().then(user => {
  if (user) {
    router.push({ path: "/storage" });
  }
});

const routes = [
  {
    path: "/storage",
    name: "Storage",
    component: Storage,
    meta: { requiresAuth: true }
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: { requiresAuth: true }
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth
  },
  {
    path: "*",
    redirect: { name: "Storage" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    user = await getUser();
    if (!user) {
      return next({
        path: "/auth",
        query: {
          redirect: to.fullPath
        }
      });
    }
    return next();
  }
  return next();
});

export default router;
