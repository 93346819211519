<template>
  <v-container fluid fill-height>
    <v-row justify="center">
      <amplify-authenticator
        :authConfig="configurationOptions"
      ></amplify-authenticator>
    </v-row>
  </v-container>
</template>
<script>
// import { onAuthUIStateChange } from "@aws-amplify/ui-components";

export default {
  name: "AuthStateApp",
  data: () => {
    return {
      configurationOptions: {
        signInConfig: {
          isSignUpDisplayed: false
        }
      }
    };
  }
};
</script>
