import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: "#4caf50",
        secondary: "#8bc34a",
        accent: "#cddc39",
        error: "#ffeb3b",
        warning: "#ffc107",
        info: "#ff5722",
        success: "#795548"
      },
      dark: {
        primary: "#03B14B",
        secondary: "#04E762",
        accent: "#cddc39",
        error: "#ffeb3b",
        warning: "#ff5722",
        info: "#cddc39",
        success: "#04E762"
      }
    }
  }
});
