<template>
  <div>
    <v-card class="mt-5">
      <FileTable
        :items="s3Data"
        :selected-key="selectedKey"
        :is-loading="isLoading"
        @item-selected="itemSelected"
        @refresh="refresh"
        @create-presigned-url="openPresignedUrlDialog"
        @create-upload-url="openUploadUrlDialog"
        @delete="deleteObject('confirmation')"
        @upload="selectFile"
      />
    </v-card>
    <ModalDeleteConfirmation
      :disabled="deleteDialogVisible"
      :targetName="selectedKey"
      @confirmed="deleteObject('delete')"
      @close="closeDeleteConfirmationModal"
    />
    <ModalCreatePresignedUrl
      :dialog="createPresignedUrlDialogVisible"
      :presigned-url="presignedUrl"
      @close="closeCreatePresignedUrlModal"
      @create-presigned-url="createPresignedUrl"
    />

    <ModalCreateUploadUrl
      :dialog="createUploadUrlDialogVisible"
      :presigned-url="uploadUrl"
      @close="closeCreateUploadUrlModal"
      @create-upload-url="createUploadUrl"
    />
    <input type="file" @change="upload" ref="input" style="display:none;" />
  </div>
</template>

<script>
// import { Storage } from "aws-amplify";
import FileTable from "../../components/commons/FileTable/index.vue";
import ModalDeleteConfirmation from "../../components/commons/ModalDeleteConfirmation/index.vue";
import ModalCreatePresignedUrl from "../../components/commons/ModalCreatePresignedUrl/index.vue";
import ModalCreateUploadUrl from "../../components/commons/ModalCreateUploadUrl/index.vue";

export default {
  name: "Storage",
  components: {
    FileTable,
    ModalDeleteConfirmation,
    ModalCreatePresignedUrl,
    ModalCreateUploadUrl
  },
  data: () => {
    return {
      deleteDialogVisible: false,
      createPresignedUrlDialogVisible: false,
      createUploadUrlDialogVisible: false
    };
  },
  props: {
    s3Data: {
      default: []
    },
    selectedObject: {
      type: [Object, null],
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    presignedUrl: {
      type: String,
      default: ""
    },
    uploadUrl: {
      type: String,
      default: ""
    }
  },
  created() {
    this.refresh();
  },
  computed: {
    selectedKey() {
      if (this.selectedObject && this.selectedObject.key) {
        return this.selectedObject.key;
      }
      return "";
    }
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    selectFile() {
      this.$refs.input.click();
    },
    upload(e) {
      const files = e.target.files || e.dataTransfer.files;
      this.$emit("upload", files[0]);
    },
    itemSelected(item) {
      this.$emit("item-selected", item);
    },
    deleteObject(cmd) {
      if (cmd === "confirmation") {
        this.deleteDialogVisible = true;
      } else if (cmd === "delete") {
        this.deleteDialogFalse = false;
        this.$emit("delete");
      }
    },
    closeDeleteConfirmationModal() {
      this.deleteDialogVisible = false;
    },
    closeCreatePresignedUrlModal() {
      this.createPresignedUrlDialogVisible = false;
    },
    createPresignedUrl(expiresIn) {
      this.$emit("create-presigned-url", expiresIn);
    },
    openPresignedUrlDialog() {
      this.createPresignedUrlDialogVisible = true;
    },
    checkApi() {
      this.$store.dispatch("storage/createPresignedUrl", {});
    },
    openUploadUrlDialog() {
      this.createUploadUrlDialogVisible = true;
    },
    closeCreateUploadUrlModal() {
      this.createUploadUrlDialogVisible = false;
    },
    createUploadUrl(expiresIn, fileKey) {
      this.$emit("create-upload-url", expiresIn, fileKey);
    }
  }
};
</script>

<style></style>
