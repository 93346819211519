/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:94e70eea-674e-4ef5-bc6a-20ed36794e6f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_5mA5PVOR2",
    "aws_user_pools_web_client_id": "1n7o6h1fknmro1l5616fk1q1n1",
    "oauth": {},
    "aws_user_files_s3_bucket": "s3-wrapper-app-storage190647-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "amplifyS3PresignedUrlAPI",
            "endpoint": "https://jsy0vlq8y7.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "amplifyUserListAPI",
            "endpoint": "https://981ec22616.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "s3wrapper-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3vklw0a427juk.cloudfront.net"
};


export default awsmobile;
