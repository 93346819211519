<template>
  <div>
    <v-card class="mt-5">
      <UsersTable
        :items="userList"
        :is-loading="isLoading"
        @refresh="refresh"
      />
    </v-card>
  </div>
</template>

<script>
import UsersTable from "../../components/commons/UsersTable/index";
export default {
  name: "UsersPagePresentational",
  components: {
    UsersTable
  },
  data: () => {
    return {};
  },
  props: {
    userList: {
      default: []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    }
  }
};
</script>

<style></style>
