<template>
  <v-data-table
    :v-model="selected"
    @item-selected="itemSelected"
    :headers="headers"
    :items="items"
    single-select
    item-key="key"
    show-select
    class="elevation-1"
    :loading="isLoading"
    loading-text="Loading..."
  >
    <template v-slot:top>
      <v-row>
        <v-col>
          <h2 class="ma-4">Storage</h2>
        </v-col>
        <v-col xl="7" lg="8" md="12" sm="12" class="text-end">
          <v-btn outlined class="ma-3" large @click="$emit('refresh')">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-btn
            outlined
            class="ma-3"
            large
            :disabled="!isSelected"
            @click.stop="$emit('create-presigned-url')"
          >
            <v-icon left>mdi-key-link</v-icon>
            Create Download URL
          </v-btn>
          <v-btn
            outlined
            class="ma-3"
            large
            @click.stop="$emit('create-upload-url')"
          >
            <v-icon left>mdi-upload-lock-outline</v-icon>
            Create Upload URL
          </v-btn>
          <v-btn
            outlined
            class="ma-3"
            large
            :disabled="!isSelected"
            @click="$emit('delete')"
          >
            <v-icon left>mdi-trash-can-outline</v-icon>
            Delete
          </v-btn>
          <v-btn
            outlined
            class="ma-3"
            color="secondary"
            large
            @click="$emit('upload')"
          >
            <v-icon left> mdi-file-plus-outline</v-icon>
            Upload
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.lastModified="{ item }">
      <span>{{ new Date(item.lastModified).toLocaleString() }}</span>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      selected: [],
      headers: [
        {
          text: "File",
          align: "start",
          sortable: false,
          value: "key"
        },
        { text: "Updated", value: "lastModified" },
        { text: "Size", value: "size" }
      ]
    };
  },
  computed: {
    isSelected() {
      return this.selectedKey === "" ? false : true;
    }
  },
  props: {
    items: {
      default: []
    },
    selectedKey: {
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    itemSelected(event) {
      this.$emit("item-selected", event);
    }
  }
};
</script>
